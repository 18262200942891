import {Component, NgZone, OnInit} from '@angular/core';
import {App} from '@capacitor/app';
import {AuthService} from '@auth0/auth0-angular';
import {mergeMap} from 'rxjs/operators';
import { Browser } from '@capacitor/browser';
import {environment} from '../environments/environment';
import {Capacitor} from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import {Platform} from '@ionic/angular';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {AuthLocalService} from './services/auth.service';
import {AnalyticsService} from './services/analytics.service';
import {register} from "swiper/element/bundle";

const callbackUri = `${environment.appPackageID}://${environment.auth0Domain}/capacitor/${environment.appPackageID}/callback`;

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})

export class AppComponent implements OnInit {

  lang = 'en';

  constructor(public auth: AuthService,
              public authLocal: AuthLocalService,
              private readonly ngZone: NgZone,
              public platform: Platform,
              private readonly translate: TranslateService,
              private readonly analytics: AnalyticsService) {
    const activeLang = this.authLocal.getVarFromStorage('lang');
    if(activeLang) {
      this.lang = 'en';
      this.translate.setDefaultLang(activeLang);
      this.translate.use(activeLang);
    } else {
      this.translate.setDefaultLang('en');
    }
    this.platform.ready().then(() => {
      this.setStatusBarStyleDark();
      SplashScreen.hide();
    });
    this.analytics.initFirebase();
  }

  async setStatusBarStyleDark() {
    if(Capacitor.isNativePlatform()) {
      await StatusBar.setStyle({ style: Style.Dark });
    }
  };

  ngOnInit(): void {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.lang = event.lang;
    });
    if(Capacitor.isNativePlatform()) {
      App.addListener('appUrlOpen', ({url}) => {
        this.ngZone.run(() => {
          if (url?.startsWith(callbackUri)) {
            if (
              url.includes('state=') &&
              (url.includes('error=') || url.includes('code='))
            ) {
              this.auth
                .handleRedirectCallback(url)
                .pipe(mergeMap(() => Browser.close()))
                .subscribe();
            } else {
              Browser.close();
            }
          }
        });
      });
    }
  }
}

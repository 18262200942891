// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  auth0CallBackUrl: 'https://dev-aid.layerjot.com',
  auth0Domain: 'layerjot-dev.us.auth0.com',
  auth0Audience: 'https://layerjot-dev.us.auth0.com/api/v2/',
  auth0ClientID: 'I4FVeeK2MgPSsDSn94v4ZIBQynsG68m7',
  appPackageID: 'com.layerjot.hub.aid',
  apiPath: 'https://dev-api.layerjot.com/api/1.0',
  apiPathV2: 'https://dev-api.layerjot.com/api/v2',
  defaultOrgInviteCode: 'AESCULAP2023',
  aidOrgUUID: 'abd590ac-f968-4934-b1c6-2aab3c9da876',
  sidOrgUUID: '41c99e92-7d17-424e-892e-b57796c16b48',
  unsplashAccessKey: 'jYUoUzoFOm80PYojnQ9cbdu59hU3K-sqwK4ycRMEvPU',
  supportEmail: 'support+aid@layerjot.com',
  pegBoardUsers: ['@layerjot.com', 'a@b.co'],
  gaTrackerID: 'UA-157592946-1',
  trackAnalytics: false,
  guideInstList: ['4505'],
  aidAnalogs: [
    {s: 'MO1600-S', a: 'BC937R'},
    {s: 'SU3300', a: 'BT601R'},
    {s: 'SU2005', a: 'BC861R'}
  ],
  cookieDomain: '.layerjot.com',
  shortEnv: 'dev',
  mediaPath: 'https://dev-media.layerjot.com'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import {Platform} from "@ionic/angular";

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  private firebaseInst: any;
  private plt = 'desktop';

  constructor (private readonly platform: Platform) {
  }

  initFirebase() {
    if(environment.trackAnalytics) {
      const app = initializeApp(environment.firebaseConfig);
      this.firebaseInst = getAnalytics(app);
    }
  }

  trackEvent(eventName: string, params: any = {}) {
    if(!this.firebaseInst) {
      this.initFirebase();
    }
    if(this.firebaseInst) {
      logEvent(this.firebaseInst, eventName, { ...params, device: this.getPlatformName()});
    }
  }

  getPlatformName() {
    if(!this.plt) {
      const platforms = this.platform.platforms();
      this.plt = platforms.length > 0 ? platforms[0] : 'desktop';
    }
    return this.plt;
  }

}
